<template>
	<div class="mx-5">
		<Loader v-if="organizationsLoad" />
		<div v-else class="container flex flex-col m-auto">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<h3 class="mt-10 text-lg font-medium text-gray-900 leading-6">
						{{ $i18n("org.titles") }}
					</h3>
					<router-link
						:to="{ path: `organization/create` }"
						class="inline-flex items-center justify-center inline-block px-4 py-2 my-5 text-base font-medium text-white bg-green-600 border border-transparent whitespace-nowrap rounded-md shadow-sm hover:bg-green-700"
					>
						{{ $i18n("action.create") }}
					</router-link>
					<div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-gray-50">
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("misc.name") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("org.status.active") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("org.user-count") }}
									</th>
									<th scope="col" class="relative px-6 py-3"></th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr
									v-for="{ id, name, active, users_count } in organizations"
									:key="`${id}_${active}`"
								>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ name }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 whitespace-nowrap">
										<t-toggle
											:checked="active"
											@change="
												(active) => {
													updateStatus(id, active);
												}
											"
											class="mr-5"
										/>
									</td>
									<td class="py-4 pr-6 text-sm text-gray-500 whitespace-nowrap">
										<router-link
											:to="{ path: `users/${id}` }"
											class="ml-5 text-blue-600 underline hover:text-blue-900"
										>
											{{ users_count }}
										</router-link>
									</td>
									<td class="flex py-4 pr-6 text-sm font-medium text-right whitespace-nowrap">
										<router-link
											:to="{ path: `organization/update/${id}` }"
											class="mr-5 text-blue-600 hover:text-blue-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
												/>
											</svg>
										</router-link>
										<a
											href="#"
											@click="deleteOrganizationByIdMethod({ id, name })"
											class="text-red-600 hover:text-red-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
												/>
											</svg>
										</a>
										<router-link
											:to="{ path: `users/${id}` }"
											class="ml-5 text-blue-600 hover:text-blue-900"
										>
											<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
											</svg>
										</router-link>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
	name: "Organizations",
	components: {
		Loader,
	},
	computed: {
		...mapGetters("organizations", ["organizations", "organizationsLoad"]),
	},
	methods: {
		...mapActions("organizations", ["fetchOrganizations", "updateOrganizationById", "deleteOrganizationById"]),
		async fetch() {
			try {
				await this.fetchOrganizations();
			} catch (error) {
				console.debug(error);
			}
		},
		async deleteOrganizationByIdMethod({ id, name }) {
			try {
				const {
					$dialog: { confirm },
					$i18n,
					fetch,
				} = this;

				const { isOk } = await confirm({
					clickToClose: false,
					escToClose: false,
					title: $i18n("action.delete") + " " + name + " ?",
					icon: "warning",
					cancelButtonText: $i18n("action.cancel"),
					okButtonText: $i18n("action.do"),
				});
				if (isOk) {
					await this.deleteOrganizationById({ id });
					await fetch();
				}
			} catch (error) {
				console.debug(error);
			}
		},
		async updateStatus(id, active) {
			try {
				await this.updateOrganizationById({
					organizationId: id,
					payload: {
						active,
					},
				});
			} catch (error) {
				console.debug(error);
			}
		},
	},
	async created() {
		try {
			await this.fetch();
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
